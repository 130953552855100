<template>
	<div>
		<Header2022 :navbg='true'></Header2022>
		<div class="type-list">
			<div class="title">VR列表</div>
			<div class="type-item" v-for="(vr, index) of vrList" :key="index" @click="vrTap(vr.url)">
				<div class="img-wrap">
					<img :src="vr.image" mode="widthFix" />
				</div>
				<div class="name">{{ vr.title }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header2022 from '@/components/header2022.vue'
	import utils from '@/common/utils.js'
	import {
		mapState
	} from 'vuex'
	export default {
		data() {
			return {
				vrList: [],
			}
		},
		components: {
			Header2022,
		},
		computed: {
			...mapState(['houseApi']),
		},
		watch: {
			'$route': {
				handler: function(to) {
					if (to.name == "vrList" && to.query.id) {
						this.getVrList(to.query.id,to.query.vr_type);
					}
				},
				immediate: true,
			}
		},
		methods: {
			getVrList(house_type_id, vr_type) {
				this.$axios.get(this.houseApi + 'home_vr/list_by_product_id', {
						params: {
							house_type_id,
							vr_type
						}
					})
					.then(res => {
						if (res.data.status > 0) {
							this.vrList = res.data.data;
						} else {
							this.$u.toast(res.data.message);
						}
					})
					.catch(error => {
						// this.$message.error(error.response.data.message);
					});
			},
			vrTap: utils.debounce(
				function(url) {
					window.open(url, "_blank");
				},
				500,
				true
			),
		}
	}
</script>

<style lang="scss" scoped>
	.type-list {
		width: 638px;
		box-sizing: border-box;
		margin: 100px auto;
		color: #474d65;
		.title{
			font-size: 40px;
			margin-bottom: 40px;
			text-align: center;
		}
		.type-item {
			width: 100%;
			box-shadow: 0px 7px 10px 0px rgba(221, 221, 221, 0.34);
			background-color: #fff;
			border-radius: 12px;
			overflow: hidden;
			margin-bottom: 40px;
			
			.img-wrap {
				position: relative;
				width: 100%;
				height:242px;
				overflow:hidden;
				img {
					position: absolute;
					top: 50%;  
					left: 50%;  
					transform: translate(-50%, -50%); /* 注意这里的逗号应该是英文逗号 */  
					max-width: 100%;  
					height: auto;  
					object-fit: cover; /* 保持原始宽高比并填充容器 */  
				}
			}

			.name {
				font-size: 32px;
				font-weight: bold;
				text-align: center;
				height: 80px;
				line-height: 80px;
			}
		}
	}
</style>